export const POST_SEARCH_LIST_REQUEST = 'POST_SEARCH_LIST_REQUEST';
export const POST_SEARCH_LIST_SUCCESS = 'POST_SEARCH_LIST_SUCCESS';
export const POST_SEARCH_LIST_FAIL = 'POST_SEARCH_LIST_FAIL';

export const POST_DASHBOARD_REQUEST = 'POST_DASHBOARD_REQUEST';
export const POST_DASHBOARD_SUCCESS = 'POST_DASHBOARD_SUCCESS';
export const POST_DASHBOARD_FAIL = 'POST_DASHBOARD_FAIL';
export const POST_DASHBOARD_RESET = 'POST_DASHBOARD_RESET';

export const POST_CREATE_REQUEST = 'POST_CREATE_REQUEST';
export const POST_CREATE_SUCCESS = 'POST_CREATE_SUCCESS';
export const POST_CREATE_FAIL = 'POST_CREATE_FAIL';

export const COMMENT_CREATE_REQUEST = 'COMMENT_CREATE_REQUEST';
export const COMMENT_CREATE_SUCCESS = 'COMMENT_CREATE_SUCCESS';
export const COMMENT_CREATE_FAIL = 'COMMENT_CREATE_FAIL';

export const POST_COMMENTS_REQUEST = 'POST_COMMENTS_REQUEST';
export const POST_COMMENTS_SUCCESS = 'POST_COMMENTS_SUCCESS';
export const POST_COMMENTS_FAIL = 'POST_COMMENTS_FAIL';

export const POST_VOTE_REQUEST = 'POST_VOTE_REQUEST';
export const POST_VOTE_SUCCESS = 'POST_VOTE_SUCCESS';
export const POST_VOTE_FAIL = 'POST_VOTE_FAIL';

export const POST_DELETE_REQUEST = 'POST_DELETE_REQUEST';
export const POST_DELETE_SUCCESS = 'POST_DELETE_SUCCESS';
export const POST_DELETE_FAIL = 'POST_DELETE_FAIL';
