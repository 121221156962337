export const NOTIFICATIONS_REQUEST = 'NOTIFICATIONS_REQUEST';
export const NOTIFICATIONS_SUCCESS = 'NOTIFICATIONS_SUCCESS';
export const NOTIFICATIONS_FAIL = 'NOTIFICATIONS_FAIL';

export const NOTIFICATIONS_UNREAD_REQUEST = 'NOTIFICATIONS_UNREAD_REQUEST';
export const NOTIFICATIONS_UNREAD_SUCCESS = 'NOTIFICATIONS_UNREAD_SUCCESS';
export const NOTIFICATIONS_UNREAD_FAIL = 'NOTIFICATIONS_UNREAD_FAIL';

export const READ_REQUEST = 'READ_REQUEST';
export const READ_SUCCESS = 'READ_SUCCESS';
export const READ_FAIL = 'READ_FAIL';
