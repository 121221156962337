export const USER_LIST_REQUEST = 'USER_LIST_REQUEST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_RESET = 'USER_LIST_RESET';
export const USER_LIST_FAIL = 'USER_LIST_FAIL';

export const USER_LIST_RECOMMENDED_REQUEST = 'USER_LIST_RECOMMENDED_REQUEST';
export const USER_LIST_RECOMMENDED_SUCCESS = 'USER_LIST_RECOMMENDED_SUCCESS';
export const USER_LIST_RECOMMENDED_FAIL = 'USER_LIST_RECOMMENDED_FAIL';

export const USER_DETAIL_REQUEST = 'USER_DETAIL_REQUEST';
export const USER_DETAIL_SUCCESS = 'USER_DETAIL_SUCCESS';
export const USER_DETAIL_RESET = 'USER_DETAIL_RESET';
export const USER_DETAIL_FAIL = 'USER_DETAIL_FAIL';

export const LIST_FOLLOWING_SUCCESS = 'LIST_FOLLOWING_SUCCESS';
export const LIST_FOLLOWING_FAIL = 'LIST_FOLLOWING_FAIL';
export const LIST_FOLLOWING_REQUEST = 'LIST_FOLLOWING_REQUEST';

export const USER_POSTS_LIST_REQUEST = 'USER_POSTS_LIST_REQUEST';
export const USER_POSTS_LIST_SUCCESS = 'USER_POSTS_LIST_SUCCESS';
export const USER_POSTS_LIST_FAIL = 'USER_POSTS_LIST_FAIL';

export const USER_POST_DELETE_REQUEST = 'USER_POST_DELETE_REQUEST';
export const USER_POST_DELETE_SUCCESS = 'USER_POST_DELETE_SUCCESS';
export const USER_POST_DELETE_FAIL = 'USER_POST_DELETE_FAIL';

export const USER_ARTICLES_LIST_REQUEST = 'USER_ARTICLES_LIST_REQUEST';
export const USER_ARTICLES_LIST_SUCCESS = 'USER_ARTICLES_LIST_SUCCESS';
export const USER_ARTICLES_LIST_FAIL = 'USER_ARTICLES_LIST_FAIL';

export const FOLLOW_USER_REQUEST = 'FOLLOW_USER_REQUEST';
export const FOLLOW_USER_SUCCESS = 'FOLLOW_USER_SUCCESS';
export const FOLLOW_USER_FAIL = 'FOLLOW_USER_FAIL';

export const LOAD_PROFILE_SUCCESS = 'LOAD_PROFILE_SUCCESS';
export const LOAD_PROFILE_FAIL = 'LOAD_PROFILE_FAIL';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const UPDATE_USER_PHOTO_REQUEST = 'UPDATE_USER_PHOTO_REQUEST';
export const UPDATE_USER_PHOTO_SUCCESS = 'UPDATE_USER_PHOTO_SUCCESS';
export const UPDATE_USER_PHOTO_FAIL = 'UPDATE_USER_PHOTO_FAIL';

export const LOAD_MORE_USER_REQUEST = 'LOAD_MORE_USER_REQUEST';
export const LOAD_MORE_USER_SUCCESS = 'LOAD_MORE_USER_SUCCESS';
export const LOAD_MORE_USER_FAIL = 'LOAD_MORE_USER_FAIL';

export const UPDATE_USER_TAGS_FAIL = 'UPDATE_USER_TAGS_FAIL';
